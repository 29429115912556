import { type ReactNode } from 'react';

import { type Block, BlockType } from '@lp-lib/game';

import {
  CharadesActivityEditor,
  charadesGenAIAction,
} from './Activities/CharadesActivityEditor';
import {
  DrawingBlankActivityEditor,
  drawingPromptGenAIAction,
} from './Activities/DrawingBlankActivityEditor';
import {
  JeopardyActivityEditor,
  jeopardyGenAIAction,
} from './Activities/JeopardyActivityEditor';
import {
  TabooActivityEditor,
  tabooGenAIAction,
} from './Activities/TabooActivityEditor';
import {
  TeamIntroActivityEditor,
  teamIntroGenAIAction,
} from './Activities/TeamIntroActivityEditor';
import {
  WinnerCelebrationActivityEditor,
  winnerCelebrationGenAIAction,
} from './Activities/WinnerCelebrationActivityEditor';
import {
  type ActivityEditorProps,
  type ActivityGenAIRunContext,
  type ActivityGenAIRunRequest,
} from './types';

export function ActivityBlockEditor(
  props: ActivityEditorProps<Block> & {
    unsupported?: () => ReactNode;
  }
) {
  const { block } = props;
  // The editor can be resolved with the multiple fields.
  // For example, the block type and mode (scenario).
  switch (block.type) {
    case BlockType.TITLE_V2:
      return <TeamIntroActivityEditor {...props} block={block} />;
    case BlockType.JEOPARDY:
      return <JeopardyActivityEditor {...props} block={block} />;
    case BlockType.SPOTLIGHT:
      return <WinnerCelebrationActivityEditor {...props} block={block} />;
    case BlockType.ICEBREAKER:
      const internalLabel = block.fields.internalLabel.toLowerCase();
      if (block.fields.style === 'taboo') {
        return <TabooActivityEditor {...props} block={block} />;
      } else if (internalLabel.includes('charades')) {
        return <CharadesActivityEditor {...props} block={block} />;
      }
      return props.unsupported ? props.unsupported() : null;
    case BlockType.DRAWING_PROMPT:
      return <DrawingBlankActivityEditor {...props} block={block} />;
    default:
      return props.unsupported ? props.unsupported() : null;
  }
}

export function activityGenAIAction(
  req: ActivityGenAIRunRequest<Block>,
  unsupported = () => Promise.resolve()
): Promise<void | ActivityGenAIRunContext> {
  switch (req.block.type) {
    case BlockType.TITLE_V2:
      return teamIntroGenAIAction({ ...req, block: req.block });
    case BlockType.JEOPARDY:
      return jeopardyGenAIAction({ ...req, block: req.block });
    case BlockType.SPOTLIGHT:
      return winnerCelebrationGenAIAction({ ...req, block: req.block });
    case BlockType.ICEBREAKER:
      const internalLabel = req.block.fields.internalLabel.toLowerCase();
      if (internalLabel.includes('taboo')) {
        return tabooGenAIAction({ ...req, block: req.block });
      } else if (internalLabel.includes('charades')) {
        return charadesGenAIAction({ ...req, block: req.block });
      }
      return unsupported();
    case BlockType.DRAWING_PROMPT:
      return drawingPromptGenAIAction({ ...req, block: req.block });
    default:
      return unsupported();
  }
}
